document.addEventListener("DOMContentLoaded", () => {
    const modals = document.querySelectorAll('.modal');
    const modalActiveClass = 'modal--opened';
    const noScrollClass = 'no-scroll';

    modals.forEach((modal) => {
        const body = modal.querySelector('.modal__body');
        const closeButton = modal.querySelector('.modal__close');
        const cancelButton = modal.querySelector('.modal__cancel');

        if (closeButton) {
            closeButton.addEventListener('click', () => {
                closeModal(modal);
            });
        }

        if (cancelButton) {
            cancelButton.addEventListener('click', () => {
                closeModal(modal);
            });
        }

        body.addEventListener('click', (e) => {
            e._clickedOnBody = true;
        });

        modal.addEventListener('click', (e) => {
            if (e._clickedOnBody) return;

            closeModal(modal);
        });
    });

    ///////////////////////////////////////////////////////////

    const modalTriggers = document.querySelectorAll('.js-modal-trigger');

    modalTriggers.forEach((trigger) => {
        trigger.addEventListener('click', () => {
            openModal(trigger.dataset.modal);
        });
    });

    // Functions
    function openModal(id) {
        const modal = document.getElementById(id);
        document.documentElement.classList.add(noScrollClass);
        modal.classList.add(modalActiveClass);
    }

    function closeModal(obj) {
        obj.classList.remove(modalActiveClass);
        document.documentElement.classList.remove(noScrollClass);
    }

});