(function() {
    const productTabsItems = document.querySelectorAll('.js-product-tabs-item');

    productTabsItems.forEach(item => {
        const tabTargetId = item.getAttribute('data-value');
        const tabTarget = document.querySelector(`[data-tab-content-id="${tabTargetId}"]`);

        item.addEventListener('click', () => {
            const activeTab = document.querySelector('.js-product-tabs-item.active');
            activeTab.classList.remove('active');
            item.classList.add('active');

            const activeContent = document.querySelector('.js-product-tabs-content.active');
            activeContent.classList.remove('active');
            tabTarget.classList.add('active');
        })
    })
})()