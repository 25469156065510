// const catalogFilters = document.querySelectorAll('.filter__item');

// if (catalogFilters && catalogFilters.length > 0) {
//     catalogFilters.forEach(filter => {
//         const filterActiveClass = 'filter__item--active';
//         const filterHeading = filter.querySelector('.filter__item-heading');
//         const filterBody = filter.querySelector('.filter__item-body');

//         filterHeading.addEventListener('click', () => {
//             filter.classList.toggle(filterActiveClass);
//         })
//     })
// }

const addToCartButtons = document.querySelectorAll('.js-add-to-cart');

if (addToCartButtons.length > 0) {
    addToCartButtons.forEach(btn => {
        btn.addEventListener('click', () => {
            updateBasket();
            btn.textContent = 'В корзине';
        })
    })
}