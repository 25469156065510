const heroSlider = new Swiper('.hero__slider', {
  slidesPerView: 1,
  loop: true,

  slideClass: 'hero__slide',
  slideActiveClass: 'hero__slide--active',

  pagination: {
    el: '.hero__pagination',
    horizontalClass: 'hero__pagination--horizontal',
    bulletClass: 'hero__pagination-bullet',
    bulletActiveClass: 'hero__pagination-bullet--active',
    clickable: true,
  },
});

const reviewSlider = new Swiper('.reviews__slider', {
  slidesPerView: 1,
  spaceBetween: 100,
  loop: true,

  // slideClass: 'reviews__slide',
  // slideActiveClass: 'reviews__slide--active',

  navigation: {
    nextEl: '.reviews__slider-btn--next',
    prevEl: '.reviews__slider-btn--prev',
  },

  pagination: {
    el: '.reviews__pagination',
    horizontalClass: 'reviews__pagination--horizontal',
    bulletClass: 'reviews__pagination-bullet',
    bulletActiveClass: 'reviews__pagination-bullet--active',
    clickable: true,
  },
});

// Product page thumbnails
const productPhotoThumbs = new Swiper(".product-detail__thumbnails", {
  spaceBetween: 10,
  slidesPerView: 5,
  freeMode: true,
  watchSlidesProgress: true,
  direction: 'vertical',
  slideThumbActiveClass: '.product-detail__thumbnail--active',
  mousewheel: {
    releaseOnEdges: true,
  },
  breakpoints: {
    // when window width is >= 320px
    320: {
      direction: 'horizontal',
      slidesPerView: 2,
      slidesPerGroup: 1,
    },
    // when window width is >= 480px
    568: {
      direction: 'vertical',
    },
  }
});

// Product page photos
const productPhoto = new Swiper(".product-detail__photos", {
  spaceBetween: 10,
  loop: true,
  thumbs: {
    swiper: productPhotoThumbs,
  },
  navigation: {
    nextEl: ".product-detail__thumbnails-btn--next",
    prevEl: ".product-detail__thumbnails-btn--prev",
  },
});