import 'core-js/stable';
import 'regenerator-runtime/runtime';

import './header.js';
import './sliders.js';
import './catalog.js';
import './product-card.js';
import './cart_ajax.js';
import './modal.js';
import './fancybox.js';
import './product-tabs.js'

