document.addEventListener('DOMContentLoaded', function () {
    document.querySelectorAll('.js-add-to-cart').forEach(function (button) {
        button.addEventListener('click', function () {
            var productId = this.getAttribute('data-product-id');
            BX.ajax({
                url: '/bitrix/components/bitrix/catalog.product.subscribe/ajax.php',
                method: 'POST',
                dataType: 'json',
                data: {
                    sessid: BX.bitrix_sessid(),
                    action: 'ADD2BASKET',
                    id: productId
                },
                onsuccess: function (result) {
                    BX.onCustomEvent('OnBasketChange');
                }
            });
        });
    });
});
