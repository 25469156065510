// Elements
const headerEl = document.getElementById('header');
const headerTop = document.querySelector('.header__top');
const headerNavEl = document.querySelector('.header__nav');
const headerNavMobileEl = document.getElementById('mobile-menu');
const headerHamburgerEl = document.getElementById('nav-hamburger');
const headerHamburgerMobileEl = document.getElementById('nav-mobile-hamburger');
const mainWrapper = document.querySelector('main');


// Classes
const documentNoScrollClass = 'no-scroll';
const headerStickedClass = 'header--sticky';
const headerOpenedClass = 'header--opened';
const headerNavOpenedClass = 'header__nav--opened';
const headerNavMobileOpenedClass = 'nav-mobile--opened';
const hamburgerOpenedClass = 'hamburger--opened';

// Togglers
const header = {
    isOpen: false,
    stickyEnable: function () {
        headerEl.classList.add(headerStickedClass);
        // const headerHeight = headerTop.offsetHeight;
        // mainWrapper.style.paddingTop = `${headerHeight}px`;
    },
    stickyDisable: function () {
        headerEl.classList.remove(headerStickedClass);
        // mainWrapper.style.paddingTop = '';
    },
    openToggle: function (el, mobile) {
        if (!this.isOpen) {
            this.isOpen = true;
            el.classList.add(hamburgerOpenedClass);
            headerEl.classList.add(headerOpenedClass);

            if (mobile) {
                headerNavMobileEl.classList.add(headerNavMobileOpenedClass)
                document.documentElement.classList.add(documentNoScrollClass);
            };
        } else {
            this.isOpen = false;
            el.classList.remove(hamburgerOpenedClass);
            headerEl.classList.remove(headerOpenedClass);

            if (mobile) {
                headerNavMobileEl.classList.remove(headerNavMobileOpenedClass);
                document.documentElement.classList.remove(documentNoScrollClass);
            }
        }
    },

}

/**
 * Sticky header begin
 **/

const sticky = headerEl.offsetTop;

function stickyHeader() {
    if (window.pageYOffset > sticky) {
        header.stickyEnable();
    } else {
        header.stickyDisable();
    }
}

window.addEventListener('scroll', stickyHeader);

/**
 * Sticky header end
 **/


/**
 * Hamburger begin
 **/

headerHamburgerEl.addEventListener('click', () => {
    header.openToggle(headerHamburgerEl);
})

headerHamburgerMobileEl.addEventListener('click', () => {
    header.openToggle(headerHamburgerMobileEl, true);
})

/**
 * Hamburger end
 **/

/**
 * Catalog dropdown
 **/

const dropdownTrigger = document.getElementById('catalog-submenu-trigger');
const dropdownCategory = document.getElementById('catalog-dropdown');
const dropdownCategoryActiveClass = 'category-dropdown__wrap--opened';

if (dropdownTrigger) {
    dropdownTrigger.addEventListener('click', (event) => {
        event.stopPropagation();
        dropdownCategory.classList.toggle(dropdownCategoryActiveClass);
    });

    document.addEventListener('click', (event) => {
        if (!dropdownTrigger.contains(event.target) && !dropdownCategory.contains(event.target)) {
            dropdownCategory.classList.remove(dropdownCategoryActiveClass);
        }
    });

    dropdownCategory.addEventListener('click', (event) => {
        event.stopPropagation();
    });
}

/**
 * Mobile menu
 **/

const mobileDropdownItems = headerNavMobileEl.querySelectorAll('.nav-mobile__item--dropdown');

mobileDropdownItems.forEach(el => {
    const link = el.querySelector('.nav-mobile__link');
    const menu = el.querySelector('.nav-mobile__dropdown');
    const linkOpenedClass = 'nav-mobile__link--opened';

    link.addEventListener('click', () => {
        link.classList.toggle(linkOpenedClass);
    })
})

const mobileAnchors = headerNavMobileEl.querySelectorAll('.js-mobile-anchor');

mobileAnchors.forEach(el => {
    el.addEventListener('click', () => {
        header.openToggle(headerHamburgerMobileEl, true);
    })
})